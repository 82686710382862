/**
 * @file   src\containers\ForgotPassword.tsx
 * @brief  ForgotPassword page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../assets/styles/icestyle.css';
import { Container } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import logo from '../assets/img/Logo_Inner.png';
import error from '../assets/img/error.svg';
import check from '../assets/img/check.svg';
import { useLocation } from 'react-router-dom';

const IceInvitation = () => {
  const [invitationStatus, setInvitationStatus] = useState(0);
  const [displayMessage, setDisplayMessage] = useState<string>();
  const [isExpired, setIsExpired] = useState(false);
  const [isSuccessResponse, setSuccessResponse] = useState(false);
  const search = useLocation().search
  let ii = new URLSearchParams(search).get("ii")
  let tk = new URLSearchParams(search).get("tk")


  useEffect(() => {
    setSuccessResponse(false);
    const payload = {
      invitationid: ii ? +ii : 0,
    };

    const base_url = 'https://api.pawsqv.com/user/auth/geticeinvitationstatus';
    // const base_url = 'https://staging-api.pawsqv.com/user/auth/geticeinvitationstatus';
    // const base_url = 'https://petalert-api.zcodemo.com/user/auth/geticeinvitationstatus';
    const APIKey = "022258244a370470853351f0b03ef71b";

    fetch(`${base_url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tk}`,
        'APIKey': APIKey
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data.code == undefined) {
          setInvitationStatus(data.statusid);
          setIsExpired(false);
          setSuccessResponse(false);
        }
        else {
          setIsExpired(true);
          setDisplayMessage(data.error);
          setSuccessResponse(false);
        }
      })
      .catch(error => {
      });
  }, [ii, tk])

  const HandleResponse = (val: boolean) => {
    const payload = {
      invitationid: ii ? +ii : 0,
      statusid: val === true ? 3 : 4
    };

    const base_url = 'https://api.pawsqv.com/user/auth/updateiceinvitationstatus';
    // const base_url = 'https://staging-api.pawsqv.com/user/auth/updateiceinvitationstatus';
    // const base_url = 'https://petalert-api.zcodemo.com/user/auth/updateiceinvitationstatus';
    const APIKey = "022258244a370470853351f0b03ef71b";

    fetch(`${base_url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tk}`,
        'APIKey': APIKey
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setInvitationStatus(0);
        setSuccessResponse(true);
      })
      .catch(error => {
      });
  }


  return (
    <>
      <Container fluid className="ice_bg">
        <nav className="navbar navbar-expand-md navbar-light fixed-top bg-white header-bar pt-3 pb-3 me-0 ms-0 w-100">
          <div className="container-fluid">
            <a className="navbar-brand" href="index.html"><img src={logo} alt="paws quivive" /></a>
          </div>
        </nav>
        <div className="pt-104 w-100 h-100 clearfix ">
          <div className="content">
            <div className="row">
              <div className=" col-md-12">
                {isExpired == true &&
                  <div id="invitationRejectedStatusDiv">
                    <div className="bdrStyle text-center ">
                      <div className="pb-4 pt-4"><img src={error} alt="" /></div>
                      <h5>{displayMessage}</h5>
                    </div>
                  </div>
                }
                {invitationStatus === 1 &&
                  <div id="invitationReqStatusDiv">
                    <div className="bdrStyle">
                      <p>Paws QuiVive invites your cooperation as an ICE contact to help protect our user & any household pets. We notify ICE contacts in the event of a non-response to a wellness-check alert.</p>
                      <button type="submit" className="btn btn-iceprimary" onClick={() => HandleResponse(true)}>Accept</button>
                      <button type="submit" className="btn btn-secondary" onClick={() => HandleResponse(false)}>Reject</button>
                    </div>
                  </div>
                }
                {invitationStatus === 2 &&
                  <div id="invitationReqStatusDiv">
                    <div className="bdrStyle">
                      <p>Paws QuiVive invites your cooperation as an ICE contact to help protect our user & any household pets. We notify ICE contacts in the event of a non-response to a wellness-check alert.</p>
                      <button type="submit" className="btn btn-iceprimary" onClick={() => HandleResponse(true)}>Accept</button>
                      <button type="submit" className="btn btn-secondary" onClick={() => HandleResponse(false)}>Reject</button>
                    </div>
                  </div>
                }
                {invitationStatus === 3 &&
                  <div id="invitationAcceptedStatusDiv">
                    <div className="bdrStyle text-center ">
                      <div className="pb-4 pt-4"><img src={error} alt="" /></div>
                      <h5>This invitation has already been accepted. </h5>
                    </div>
                  </div>
                }
                {invitationStatus === 4 &&
                  <div id="invitationRejectedStatusDiv">
                    <div className="bdrStyle text-center ">
                      <div className="pb-4 pt-4"><img src={error} alt="" /></div>
                      <h5>This invitation has already been rejected. </h5>
                    </div>
                  </div>
                }
                {invitationStatus === 5 &&
                  <div id="invitationExpiredStatusDiv">
                    <div className="bdrStyle text-center ">
                      <div className="pb-4 pt-4"><img src={error} alt="" /></div>
                      <h5>This link has already expired. </h5>
                    </div>
                  </div>
                }
                {invitationStatus === 6 &&
                  <div id="invitationResStatusDiv">
                    <div className="bdrStyle text-center ">
                      <div className="pb-4 pt-4"><img src={error} alt="" /></div>
                      <h5>This user has been deleted. </h5>
                    </div>
                  </div>
                }
                {isSuccessResponse &&
                  <div id="invitationResStatusDiv">
                    <div className="bdrStyle text-center ">
                      <div className="pb-4 pt-4"><img src={check} alt="" /></div>
                      <h5>Thank you for your response. </h5>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default IceInvitation;
